import { createGlobalStyle } from 'styled-components';

// Typescript ------------------------------------------------------------------
export interface ThemeType {
  breakpoints: {
    tablet: string;
    desktop: string;
  };
  colors: {
    primaryBlue: string;
    secondaryBlue: string;
    softBlack: string;
    softWhite: string;
    orangeCTA: string;
    black: string;
    white: string;
    grey: string;
  };
  shadows: {
    blue: string;
    grey: string;
  };
  typography: {
    fontFamily: {
      headingFont: string;
      bodyFont: string;
    };
    lineHeight: {
      [key: string]: {
        default: number;
        tablet: number;
        desktop: number;
      };
    };
    fontSize: {
      [key: string]: {
        default: string;
        tablet: string;
        desktop: string;
      };
    };
    fontWeight: {
      default: string;
      light: number;
      bold: string;
    };
    letterSpacing: {
      default: string;
      tablet: string;
      desktop: string;
    };
  };
}
// -----------------------------------------------------------------------------

export const useCustomTheme = (): ThemeType => ({
  breakpoints: {
    tablet: '768px',
    desktop: '1024px',
  },
  colors: {
    primaryBlue: '#0080B2',
    secondaryBlue: '#8BD3F5',
    softBlack: '#1C1C1C',
    softWhite: '#F5F5F5',
    orangeCTA: '#FF7F50',
    black: '#000000',
    white: '#FFFFFF',
    grey: '#A0A0A0',
  },
  shadows: {
    blue: '0px 4px 20px 5px rgba(155, 209, 240, 1)',
    grey: '0px 4px 20px 5px rgba(160, 160, 160, 0.55)',
  },
  typography: {
    fontFamily: {
      headingFont: 'Merriweather, serif',
      bodyFont: 'Nunito, sans-serif',
    },
    lineHeight: {
      h1: {
        default: 1.4,
        tablet: 1.3,
        desktop: 1.2,
      },
      h2: {
        default: 1.4,
        tablet: 1.35,
        desktop: 1.3,
      },
      h3: {
        default: 1.45,
        tablet: 1.45,
        desktop: 1.4,
      },
      h4: {
        default: 1.5,
        tablet: 1.45,
        desktop: 1.4,
      },
      h5: {
        default: 1.55,
        tablet: 1.5,
        desktop: 1.45,
      },
      h6: {
        default: 1.6,
        tablet: 1.5,
        desktop: 1.45,
      },
      p: {
        default: 1.7,
        tablet: 1.65,
        desktop: 1.6,
      },
    },
    fontSize: {
      h1: {
        default: '2rem',
        tablet: '2.5rem',
        desktop: '3rem',
      },
      h2: {
        default: '1.75rem',
        tablet: '2.25rem',
        desktop: '2.5rem',
      },
      h3: {
        default: '1.5rem',
        tablet: '2rem',
        desktop: '2.25rem',
      },
      h4: {
        default: '1.25rem',
        tablet: '1.625rem',
        desktop: '1.875rem',
      },
      h5: {
        default: '1.125rem',
        tablet: '1.375rem',
        desktop: '1.625rem',
      },
      h6: {
        default: '1rem',
        tablet: '1.25rem',
        desktop: '1.375rem',
      },
      p: {
        default: '0.875rem',
        tablet: '1rem',
        desktop: '1.125rem',
      },
    },
    fontWeight: {
      default: 'normal',
      light: 300,
      bold: 'bold',
    },
    letterSpacing: {
      default: '0.05em',
      tablet: '0.04em',
      desktop: '0.03em',
    },
  },
});

export const GlobalStyle = createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        border: none;
        box-sizing: border-box;
    }
    html {
        font-size: 16px;
    }
    body {
        height: 100%;
        color: ${({ theme }) => theme.colors.softBlack};
        background: ${({ theme }) => theme.colors.white};
    }
`;
